<div class="ln-modal__close-wrapper">
  <ln-icon name="cross" width="14px" height="14px" (click)="close()" class="ln-modal__close"></ln-icon>
</div>
<div class="ln-modal__header">
  <span class="ln-modal__header-text" *ngIf="header">{{ header }}</span>
  <ng-content select="[header]"></ng-content>
</div>
<div class="ln-modal__body">
  <ng-content select="[body]"></ng-content>
</div>
<div class="ln-modal__footer">
  <ng-content select="[footer]"></ng-content>
</div>
