import { isPlatformBrowser } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
  SkipSelf,
  ViewEncapsulation
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@shared/shared.module';
import { nanoid } from 'nanoid';

@Component({
    selector: 'ln-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [SharedModule, TranslateModule]
})
export class ModalComponent implements OnInit, OnDestroy {
  @HostBinding('class') classes = 'ln-modal ln-modal__initial';
  @HostBinding('style.opacity') opacity: number = 0;
  @HostBinding('style') @Input() inlineStyle: CSSStyleDeclaration | Record<string, unknown> = {};

  @Input() id?: string;
  @Input() header?: string;
  @Input() denyCloseClickOut?: boolean;

  @Output() visible = new EventEmitter<boolean>();
  @Output() closed = new EventEmitter<number>();
  @Output() opened = new EventEmitter();

  private element: any;
  private wrapper: HTMLElement | null = null;
  _isVisible = false;
  private overlayClick = false;
  private idModal = `ln-modal__wrapper-id-${nanoid()}`;
  private docElement: Document | null = null;

  @HostListener('window:keyup', ['$event'])
  onKeyUp(e: KeyboardEvent) {
    if (e.code === 'Escape') this.close();
  }

  constructor(
    private el: ElementRef,
    @Inject(PLATFORM_ID) private platformId: any,
    @SkipSelf() protected cdr: ChangeDetectorRef
  ) {
    if (!isPlatformBrowser(this.platformId)) return;
    this.docElement = document;

    this.element = el.nativeElement;
  }

  set isVisible(value: boolean) {
    if (!this.docElement) return;
    this._isVisible = value;

    const html = this.docElement.documentElement;
    if (!value) {
      setTimeout(() => {
        if (!this._isVisible) {
          if (this.wrapper) this.wrapper.classList.add('ln-modal__wrapper-hide');
          this.removeFromDOM();
        }
      }, 300);
      html.classList.remove('ln-modal__wrapper-block-scroll');
    } else {
      html.classList.add('ln-modal__wrapper-block-scroll');
    }
    this.cdr.markForCheck();

    this.visible.emit(value);
  }

  get isVisible() {
    return this._isVisible;
  }

  ngOnInit(): void {
    if (!isPlatformBrowser(this.platformId)) return;

    this.addWrapper();
  }

  ngOnDestroy(): void {
    if (this.wrapper) {
      this.removeFromDOM();
      this.wrapper.removeEventListener('mousedown', this.onDownOverlay());
      this.wrapper.removeEventListener('mouseup', this.onUpOverlay());
      if (!this.wrapper.hasChildNodes()) {
        this.wrapper.remove();
        this.wrapper = null;
      }
    }
  }

  addWrapper() {
    if (this.wrapper || !this.docElement) return;

    this.wrapper = this.docElement.querySelector('#' + this.idModal) || this.docElement.createElement('div');
    this.wrapper.id = this.idModal;
    this.wrapper.classList.add('ln-modal__wrapper', 'ln-modal__wrapper-hide');
    this.setWrapperOpacity(0);
    this.wrapper.addEventListener('mousedown', this.onDownOverlay());
    this.wrapper.addEventListener('mouseup', this.onUpOverlay());
    this.wrapper.appendChild(this.element);
    this.docElement.body.appendChild(this.wrapper);
    setTimeout(() => (this.classes = 'ln-modal'));
  }

  removeFromDOM() {
    if (this && this.wrapper && this.wrapper.contains(this.element)) {
      this.wrapper.removeChild(this.element);
    }
  }

  setWrapperOpacity(val: number) {
    if (this.wrapper) this.opacity = val;
    this.cdr.detectChanges();
  }

  open(): void {
    if (this.wrapper) {
      this.wrapper.classList.remove('ln-modal__wrapper-hide');
      this.wrapper.appendChild(this.element);
    }
    setTimeout(() => {
      this.isVisible = true;
      this.setWrapperOpacity(1);
      this.opened.emit();
    }, 1);
  }

  close(status: number = 0): void {
    setTimeout(() => {
      this.isVisible = false;
      this.setWrapperOpacity(0);
      this.closed.emit(status);
    }, 1);
  }

  onDownOverlay() {
    return (e: MouseEvent) => {
      if (!e || !(e.target instanceof Element) || !e.target.classList.contains('ln-modal__wrapper')) return;
      this.overlayClick = true;
    };
  }

  onUpOverlay() {
    return (e: MouseEvent) => {
      if (
        !this.denyCloseClickOut &&
        this.overlayClick &&
        e.target instanceof Element &&
        e.target.classList.contains('ln-modal__wrapper')
      ) {
        e.preventDefault();
        this.close();
        return;
      }
      this.overlayClick = false;
    };
  }
}
