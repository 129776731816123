@use 'vars' as *;
@use 'typography' as *;
@use 'utils' as *;
@use 'direction' as *;

.ln-modal {
  z-index: 901;
  transition: opacity 0.3s;
  position: relative;
  max-width: calc(500px + (40% - 300px));
  width: 90%;
  min-width: 50%;
  min-height: 50%;
  margin: var(--mrg-col);
  border-radius: 12px;
  box-sizing: border-box;
  border-color: var(--ln-color-primary);
  box-shadow: $shadow-big-light;
  background: var(--ln-color-background);
  opacity: 0;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (max-width: $laptop) {
    width: calc(100% - var(--mrg-col));
  }

  &__initial {
    display: none;
  }

  &__header {
    flex: 0;
    padding: var(--mrg-row);

    &-text {
      @include title-text;
      @include margin-end(20px, false);
    }
  }

  &__body {
    flex: 1;
    overflow-y: auto;
  }

  &__header,
  &__body {
    border-bottom: 1px solid var(--ln-color-text-gray-20);
  }

  &__body,
  &__footer {
    padding: var(--mrg-row);
  }

  &__footer:empty {
    display: none;
  }

  &:active,
  &:focus {
    outline: none;
  }

  &__close-wrapper {
    @include rtl-prop(right, left, 0, false);

    position: absolute;
    top: 0;
    margin: var(--mrg-row);
    cursor: pointer;
  }

  &__close {
    @include rtl-value(transform, translateX(-100%), translateX(100%), false);

    color: var(--ln-color-primary);
    position: fixed;
  }

  &__wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    z-index: 900;
    transition: all 0.5s;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    flex-grow: 0;

    &-hide {
      display: none;
    }

    &::before {
      content: '';
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0.55;
      z-index: 899;
      background: #111;
    }

    &-block-scroll {
      overflow-y: scroll;
      margin: 0;
      height: 100%;
    }
  }
}
